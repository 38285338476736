import React, { useState, useRef } from "react";
import { Arrow, BlackBar, ContactBanner, ContactImg } from "../../assets";
import "../../CustomStyles/index.css";
import { Header } from "../../components";
import { Button } from "flowbite-react";
import axios from "axios";
import { Form, Formik, useFormik } from "formik";
import * as Yup from 'yup'
import { Popup } from "../../components/Modal";
import emailjs from '@emailjs/browser'
import { Spinner } from "flowbite-react";


const ContactUs = () => {
  const [client, setClient] = useState({
    name: "",
    email: "",
    phoneNo: "",
    question1: "",
    question2: "",
    message: "",
  })
  const [isModal, setIsModal] = React.useState(false);
  const [isMessage, setIsMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const formRef = useRef(null)
  const modalMsg = "Thanks for contacting us! \n We will be in touch with you shortly."
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const schema = Yup.object().shape({
    name: Yup.string().required('Please enter your name'),
    email: Yup.string().email('Please enter a valid email address').required('Please enter your email'),
    phoneNo: Yup.string().max(11).matches(phoneRegExp, 'Phone number is not valid'),
    message: Yup.string().required('Please enter a message'),
  });
  function isEmailValid(email) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    ) {
      return true
    } else return false;
  }

  const sendEmail = (e) => {

    e.preventDefault();
    if (isEmailValid(client.email)) {
      setLoading(true)
      emailjs.sendForm('service_yiadsly', 'template_luw8ll2', formRef.current, 'lcwEHWLMo5bDI_pXg')
        .then((result) => {
          console.log(result.text);
          setLoading(false)
          setIsModal(true)
          setIsMessage(modalMsg)
          setClient({
            name: "",
            email: "",
            phoneNo: "",
            question1: "",
            question2: "",
            message: "",
          })
        }, (error) => {
          console.log(error.text);
        });
    } else {
      // setIsModal(true)
      setError(true)
    }
  }

  const handleInputChange = (name, value) => {
    setClient({
      ...client,
      [name]: value,
    });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     name: '',
  //     email: '',
  //     phoneNo:'',
  //     description:'',
  //     aboutUs:'',
  //     message: '',
  //   },
  //   validationSchema: schema,
  //   onSubmit: async (values, { setSubmitting, resetForm }) => {
  //     try {

  //       // const response = await axios.post('http://localhost:4000/app/contact-us', values);
  //       // console.log("response is", response);
  //       // if(response?.data?.success){
  //       //   resetForm()
  //       //   setIsModal(true)
  //       // }
  //     } catch (error) {
  //       console.error(error);
  //       // Do something with the error, such as displaying an error message
  //     } finally {
  //       setSubmitting(false);
  //     }
  //   },
  // });
  return (
    <>
      <div className="bg-gray200">
        <Popup isModal={isModal} setIsModal={setIsModal} msg={isMessage} />
        <Header />
        <div className="contact-banner pt-24 pb-16">
          <div className="pt-10 mainContainer">
            <div className="px-12 py-10 contact-us rounded-3xl">
              <div className="grid grid-cols-12 gap-14 items-center">
                <div className="col-span-12 lg:col-span-6 sm:col-span-3 xs:col-span-3">
                  <img src={BlackBar} />
                  <h3 className="text-3xl font-bold py-2">Contact us</h3>
                  <form ref={formRef} className="flex flex-col gap-8 py-5" onSubmit={sendEmail}>
                    <div>
                      <input
                        value={client.name}
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                        required={true}
                        onChange={(t) => handleInputChange('name', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.name}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                      {/* {formik.touched.name && formik.errors.name ? (
                      <p className="text-red-500">{formik.errors.name}</p>
                      
                      ) : null} */}
                    </div>
                    <div>
                      <input
                        value={client.email}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="E-mail Address"
                        required={true}
                        onChange={(t) => handleInputChange('email', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.email}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                      {/* {formik.touched.email && formik.errors.email ? (
                        <p className="text-red-500">{formik.errors.email}</p>
                       ) : null} */}
                      {error && <p className="text-red-700">Please Enter valid email</p>}

                    </div>
                    <div>
                      <input
                        value={client.phoneNo}
                        id="phoneNo"
                        name="phoneNo"
                        type="integer"
                        placeholder="Phone no"
                        required={true}
                        onChange={(t) => handleInputChange('phoneNo', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.phoneNo}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                      {/* {formik.touched.phoneNo && formik.errors.phoneNo ? (
                        <p className="text-red-500">{formik.errors.phoneNo}</p>
                        ) : null} */}
                    </div>
                    <div>
                      <input
                        value={client.question1}
                        id="question1"
                        type="text"
                        name="question1"
                        placeholder="How we can help you?"
                        onChange={(t) => handleInputChange('question1', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.description}
                        required={true}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                    </div>
                    <div>
                      <input
                        value={client.question2}
                        id="question2"
                        type="text"
                        name="question2"
                        placeholder="How did you hear about us?"
                        onChange={(t) => handleInputChange('question2', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.aboutUs}
                        required={true}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                    </div>
                    <div>
                      <input
                        value={client.message}
                        id="message"
                        name="message"
                        type="message"
                        placeholder="Message"
                        required={true}
                        onChange={(t) => handleInputChange('message', t.target.value)}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.message}
                        className="border-0 border-b-2 px-0 py-3 z-0 bg-transparent w-12/12 md:w-full border-b-blue150 focus:outline-none"
                      />
                      {/* {formik.touched.message && formik.errors.message ? (
                          <p className="text-red-500">{formik.errors.message}</p>
                        ) : null} */}
                    </div>
                    <div className="py-2 flex justify-center">
                      <Button
                        type="submit"
                        // disabled={formik.isSubmitting}
                        pill={true}
                        color="#3FAEFF"
                        className="bg-white border border-blue200 text-white md:ml-0 xs:ml-16"
                      // onClick={contactUs}
                      >
                        <span className={`text-blue200 ${loading ? 'px-5' : 'px-2'}`}>{loading ? <Spinner aria-label="Spinner button example" /> :'Submit'}</span>
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <img src={ContactImg} className=" hidden sm:block w-7/12 md:w-full" />
                </div>
              </div>
            </div>
            {/* <div className="col-span-12 xl:col-span-5 bg-blue250 contact-blue px-24 pr-5 md:pr-80 xl:pr-5 py-10 pt-20">
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">
                  Headquaters
                </h2>
                <p className="text-gray100 text-sm">
                  Sysntax Root 12121 6th avenu, Fl 12 new york
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">
                  California Office
                </h2>
                <p className="text-gray100 text-sm">
                  Developing and providing web and mobile software + apps for
                  clients throughout the globe.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">
                  Give us a call
                </h2>
                <p className="text-gray100 text-sm">
                  Developing and providing web and mobile software + apps for
                  clients throughout the globe.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">
                  General inquries
                </h2>
                <p className="text-gray100 text-sm">
                  Developing and providing web and mobile software + apps for
                  clients throughout the globe.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">
                  Headquaters
                </h2>
                <p className="text-gray100 text-sm">
                  Developing and providing web and mobile software + apps for
                  clients throughout the globe.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-dark text-xl font-bold py-2">Careers</h2>
                <p className="text-gray100 text-sm">
                  Developing and providing web and mobile software + apps for
                  clients throughout the globe.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
