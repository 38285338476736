import React from "react";
import { Deployment } from "../../assets";

const DevCycleCard = ({ item, smallScreen }) => {
  return (
    <div className={`bg-gray400 px-8 ${smallScreen && `sm:py-2 xs:py-2`} xl:py-5 lg:py5 md:py-5 sm:py-5 xs:py-5  rounded-2xl h-72 min-h-full`}
    style={{ boxShadow: "0px 0px 25px 12px rgba(105, 103, 103, 0.13)" }}
    
    >
      <div className="flex justify-between py-3 items-center">
        <img src={item.image} />
        <h2 className={`text-4xl  xl:py-5 font-bold px-5 font-Roboto`}>{item.number}</h2>
      </div>
      <div className={`${smallScreen ? `sm:py-0 xs:py-0` : `sm:py-5 xs:py-4`}  xl:py-5 lg:py5 md:py-5 flex-wrap`}>
        <h3 className="text-lg text-dark50">{item.title}</h3>
        <p className={`${smallScreen ? `sm:leading-5 xs:leading-5` : `sm:leading-6 xs:leading-6`} ${smallScreen ? `xs:text-xs` : "xs:text-sm"} xl:text-base lg:text-sm md:text-sm sm:text-sm text-gray50 w-9/12 md:w-11/12 xl:w-10/12  xl:leading-6 lg:leading-6 md:leading-6   `}>
          {item.description}
        </p>
      </div>
    </div>
  );
};

export default DevCycleCard;
