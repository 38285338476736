import React, { useRef } from "react";
import { Button, Footer, Spinner } from "flowbite-react";
import {
  Arrow,
  Facebook,
  FooterLogo,
  Instagram,
  Linkedin,
  Location,
  Phone,
  Twitter,
} from "../../assets";
import { Link } from "react-router-dom";
import axios from "axios";
import { Popup } from "../Modal";
import emailjs from "@emailjs/browser";

const AppFooter = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [isMessage, setIsMessage] = React.useState("");
  const [isModal, setIsModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = useRef(null);
  // const modalMsg = "Thanks for you contact";
  const modalMsg = `Thanks for contacting us! \n We will be in touch with you shortly.`;
  const linkedinUrl = "https://www.linkedin.com/in/syntax-root-569b02267";
  const fbUrl = "https://www.facebook.com/profile.php?id=100090901290823";
  const instaUrl = "https://www.instagram.com/syntaxroot123/";
  function isEmailValid(email) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    } else return false;
  }

  const subscribe = () => {
    if (isEmailValid(email)) {
      axios
        // .post('http://localhost:4000/app/contact-us', {
        //   email:email,
        // })
        .then((res) => {
          if (res?.data?.success) {
            //     setEmail('')
            //     setIsModal(true)
            // setError(false)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setError(true);
      // alert('Please enter valid email')
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isEmailValid(email)) {
      setLoading(true);
      emailjs
        .sendForm(
          "service_yiadsly",
          "template_luw8ll2",
          formRef.current,
          "lcwEHWLMo5bDI_pXg"
        )
        .then(
          (result) => {
            console.log(result.text);
            setLoading(false);
            setEmail("");
            setIsModal(true);
            setError(false);
            setIsMessage(modalMsg);
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
          }
        );
    } else {
      // setIsModal(true)
      // setErrorMsg('Please enter valid email')
      setError(true);
    }
  };
  return (
    <div className="bg-blue200 pb-10 lg:pb-0">
      <Popup isModal={isModal} setIsModal={setIsModal} msg={isMessage} />
      <div className="mainContainer py-0 pt-5 md:pt-14 lg:pt-24 lg:py-4">
        <div className="grid grid-cols-12 gap-5 md:gap-8">
          <div className="col-span-12 md:col-span-5 footerDiv">
            <div className="w-12/12 md:w-10/12 lg:w-6/12">
              <div className="flex items-center">
                <Link to="/">
                  <img src={FooterLogo} alt="logo" />
                </Link>
              </div>
              <div col={true} className="pt-8">
                <div className="text-white text-sm py-2">
                  <div className="flex">
                    <img src={Phone} />{" "}
                    {/* <p className="pl-1">+44 (747) 722 8473</p> */}
                    <p className="pl-1">+92 300 463 9943</p>
                  </div>
                </div>
                <div className="text-white text-sm py-2">
                  <div className="flex items-start">
                    <img src={Location} />
                    <p className="pl-1 leading-6">
                      {/* 590 Foleshill Road Coventry CV6 5HP <br /> United Kingdom */}
                      H. No. 1 St. No. 2, Ahbab Colony Near Hanjarwal Police
                      Station, Multan Road, Lahore, Iqbal Town, LAHORE, Punjab
                    </p>
                  </div>
                </div>
                <div className="text-white text-xs py-2 pt-5">
                  <div className="flex items-center justify-between w-9/12">
                    <Link target="_blank" to={linkedinUrl}>
                      <img src={Linkedin} className="pr-2" />
                    </Link>
                    <Link target="_blank" to={{}}>
                      <img src={Twitter} className="pr-2" />
                    </Link>
                    <Link target={"_blank"} to={instaUrl}>
                      <img src={Instagram} className="pr-2" />
                    </Link>
                    <Link target="_blank" to={fbUrl}>
                      <img src={Facebook} className="pr-2" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="">
                <p className="text-white text-xs pt-8">
                  All Rights Reserved 2023
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-7">
            <div className="flex justify-center flex-col px-1 md:px-5 h-full">
              <Footer.LinkGroup
                col={false}
                className="grid grid-cols-12 gap-x-8 lg:gap-x-10 gap-y-3 py-5 w-12/12 lg:w-9/12"
              >
                {/* <Footer.Link
                  href="/career"
                  className="col-span-6 lg:col-span-4 font-bold text-white text-xl"
                >
                  Career
                </Footer.Link> */}
                <Footer.Link
                  href="/"
                  className="col-span-6 lg:col-span-4 font-bold text-white text-xl"
                >
                  Home
                </Footer.Link>
                <Footer.Link
                  href="/contact-us"
                  className="col-span-6 lg:col-span-4 font-bold text-white text-xl"
                >
                  Contact Us
                </Footer.Link>
                <Footer.Link
                  href="/about-us"
                  className="col-span-6 lg:col-span-4 font-bold text-white text-xl"
                >
                  About Us
                </Footer.Link>
                {/* <Footer.Link
                  href="#"
                  className="col-span-6 lg:col-span-4 font-bold text-white text-xl"
                >
                  Blog
                </Footer.Link> */}
                <Footer.Link className="col-span-6 lg:col-span-4 font-bold text-white text-xl">
                  Services
                </Footer.Link>
              </Footer.LinkGroup>
              <div>
                <h3 className="text-white text-md font-bold uppercase py-5 pt-10">
                  Drop your email we will reach out to you
                </h3>
                <form ref={formRef} onSubmit={sendEmail}>
                  <div className="w-12/12 lg:w-10/12 border border-blue300 bg-light100 rounded-full p-1 flex">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="bg-light100 rounded-full py-1 font-Roboto placeholder:uppercase px-3 w-full max-w-full border-none focus:outline-none focus:border-none"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <input
                    name="name"
                    id="name"
                    type="text"
                    className="bg-light100 rounded-full py-1 font-Roboto placeholder:uppercase px-3 w-full max-w-full border-none focus:outline-none focus:border-none"
                    // placeholder="Email"
                    value={"name is"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                   <input
                   name="phoneNo"
                   id="PhoneNo"
                   type="text"
                    className="bg-light100 rounded-full py-1 font-Roboto placeholder:uppercase px-3 w-full max-w-full border-none focus:outline-none focus:border-none"
                    // placeholder="Email"
                    value={"phoneNo"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                   name="message"
                   id="message"
                   type="text"
                    className="bg-light100 rounded-full py-1 font-Roboto placeholder:uppercase px-3 w-full max-w-full border-none focus:outline-none focus:border-none"
                    // placeholder="Email"
                    value={"message"}
                    onChange={(e) => setEmail(e.target.value)}
                  /> */}
                    <Button
                      type="submit"
                      color="#4EAAF3"
                      pill={true}
                      className="bg-blue100 text-white text-xs md:text-sm uppercase rounded-full px-0 md:px-5"
                      // onClick={subscribe}
                    >
                      {loading ? (
                        <Spinner aria-label="Spinner button example" />
                      ) : (
                        "send"
                      )}
                    </Button>
                  </div>
                </form>
                {error && (
                  <p className="text-red-700">Please Enter valid email</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
