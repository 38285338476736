import React from "react";

const IndustryCard = ({ item, bgColor }) => {
  return (
    <div
      className={`${bgColor} text-center py-8 rounded-xl h-48`}
      // style={{ boxShadow: "0px 0px 30px 10px rgba(105, 103, 103, 0.13)" }}
      style={{ boxShadow: "0px 0px 25px 12px rgba(105, 103, 103, 0.13)" }}
    >
    {/* <div className="bg-white py-5 md:py-5 lg:py-10 xl:py-8 px-5 md:px-3 xl:px-8 service-card "> */}
      <img src={item.image} className="mx-auto" />
      <h2 className="text-dark50 text-md w-5/12 lg:w-10/12 xl:w-6/12 mx-auto text-center">
        {item.name}
      </h2>
    </div>
  );
};

export default IndustryCard;
