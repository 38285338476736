import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Ellipse11 } from "../../assets";
import "../../CustomStyles/index.css";
// import { ArrowRightCircleIcon } from "@heroicons/24/outline";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

const NextArrow = (props) => {
  const { className, style, onClick, icon } = props;
  return (
    <div
      className=""
      style={{
        display: "flex",
        // background: "#3faeff",
        // marginRight: -20,
        justifyContent: "center",
        alignSelf: "center",
        fontSize: 40,
        flexDirection: "row",
      }}
      onClick={onClick}
    >
      <BsArrowRightCircle
        style={{marginRight: -9, width:28, height:28,}}
        className={`${className} -mr-60`}
        color="#3faeff"
        size={50}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "flex",
        // background: "#3faeff",
        // marginLeft: -20,
        justifyContent: "center",
        alignSelf: "center",
      }}
      onClick={onClick}
    >
      <BsArrowLeftCircle  
        style={{marginLeft: -9,  width:28, height:28,}}
        className={`${className} xs:w-6 xs:h-6`}
        color="#3faeff"
        onClick={onClick}
        // size={20}
      />
    </div>
  );
};

function SliderComponent({ data }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    // autoplay: true,
    speed: 500,
    autoplaySpeed: 20000,
    cssEase: "linear",
    centerPadding: "20px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index) => {
      setCurrentSlide(index);
    },
    //     afterChange: (index, next) => {
    //       const list = document.getElementsByClassName("slick-slide");
    //       for (let i = 0; i < list.length; i++) {
    //         if (list[i].classList.contains("slick-active")) {
    //           list[i].classList.add("slick-focused");
    //           setCurrentSlide(i);
    //         } else {
    //           list[i].classList.remove("slick-focused");
    //         }
    //         if (list[i].classList.contains("slick-center")) {
    //           list[i].classList.add("slick-center-slide");
    //         } else {
    //           list[i].classList.remove("slick-center-slide");
    //         }
    //       }
    // //         list.forEach((slide) => slide.classList.remove('slick-left', 'slick-right'));
    // //   const current = document.querySelector('.slick-center');
    // //   current.previousElementSibling.classList.add('slick-left');
    // //   current.nextElementSibling.classList.add('slick-right');
    //       },
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        },
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          marginLeft:-10
        },
      },
    ],
  };
  useEffect(() => {
    if (!sliderRef.current) {
      sliderRef.current.on("init reInit afterChange", () => {
        const centerSlide = document.querySelector(".slick-center");
        const slides = document.querySelectorAll(".slick-slide");

        // Reset color of all slides
        slides.forEach((slide) => {
          slide.style.background = "gray";
        });

        // Change color of center slide
        centerSlide.style.background = "blue";

        // Set current slide index
        for (let i = 0; i < slides.length; i++) {
          if (slides[i].classList.contains("slick-active")) {
            setCurrentSlide(i);
            break;
          }
        }
      });
    }
  }, [sliderRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      const list = document.getElementsByClassName("slick-slide");
      for (let i = 0; i < list.length; i++) {
        if (list[i].classList.contains("slick-active")) {
          list[i].classList.add("slick-focused");
        } else {
          list[i].classList.remove("slick-focused");
        }
        if (list[i].classList.contains("slick-center")) {
          list[i].classList.add("slick-center-slide");
        } else {
          list[i].classList.remove("slick-center-slide");
        }
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="">
      <Slider focusOnSelect={true} {...settings} ref={sliderRef}>
        {data?.map((item) => {
          return (
            <div
              className={`slider-container xs:mb-20 col-span-6 lg:col-span-6 md:col-span-3 rounded-xl flex-wrap py-9`}
            >
              <div className="slide-item">
                <div className="flex items-center mx-2">
                  {/* <img src={Ellipse11} /> */}
                  <div className="pl-3">
                    <p className="text-sm text-dark50 text-left ">
                      {item?.clientName}
                    </p>
                    <p className="text-sm text-gray100 ">{item.clientDest}</p>
                  </div>
                </div>
                <p style={{}} className="md:text-sm sm:text-sm xs:leading-5 xs:text-xs text-dark50 py-2 px-5 ">
                  {item?.message}
                </p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SliderComponent;
