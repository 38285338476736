import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ item }) => {
  return item.navigationLink ? (
    <Link to={`/${item.navigationLink}`}>
      <div className="bg-gray400 py-5 md:py-5 lg:py-7 xl:py-6 px-5 md:px-3 xl:px-8  rounded-2xl service-card"
      style={{boxShadow:"0px 0px 25px 12px rgba(105, 103, 103, 0.13)"}} >
        <img src={item.image} />
        <h2 className="text-dark text-2xl md:text-xl xl:text-2xl pt-4  hover:cursor-pointer">
          {item.serviceTitle}
        </h2>
        <p className="text-gray100 text-base md:text-sm sm:text-sm xs:text-sm  pt-2 pr-3">{item.sercviceDetail}</p>
      </div>
    </Link>
  ) : (
    <div className="bg-gray400 py-5 md:py-5 lg:py-10 xl:py-8 px-5 md:px-3 xl:px-8 rounded-2xl service-card "
    style={{boxShadow:"0px 0px 25px 12px rgba(105, 103, 103, 0.13)"}}
    >
      <img src={item.image} />
      <h2 className="text-dark text-2xl md:text-xl xl:text-2xl pt-5">
        {item.serviceTitle}
      </h2>
      <p className="text-gray100 text-sm  py-2 pr-3">{item.sercviceDetail}</p>
    </div>
  );
};

export default ServiceCard;
