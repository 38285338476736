import { Navbar } from "flowbite-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowBlack, ArrowDown, Brand, It_icon, It_icon2, Web_icon } from "../../assets";
import { Mobile_icon } from "../../assets";
import { Web_icon1 } from "../../assets";
import { Product_icon } from "../../assets";
import { Quality_icon } from "../../assets";
import { Consu_icon } from "../../assets";
import { Platform_icon } from "../../assets";
const Header = () => {
  const [navBg, setNavBg] = useState(false);
  const [navActive, setNavActive] = useState("/");
  const [hover, setHover] = useState(false);

  let screenHeight = window.innerHeight;

  const navPath = window.location.pathname;

  useEffect(() => {
    setNavActive(navPath);
  }, []);

  // console.log(navPath, "navPath");

  const isSticky = (e) => {
    const header = document.querySelector(".header");
    console.log('window is', window.location.pathname);
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <div className="header">
      <Navbar
        fluid={false}
        rounded={true}
        className="mainContainer xs:bg-transparent  py-10 my-1"
      >
        <Navbar.Brand className="z-10">
          <Link to="/">
            <img src={Brand} className="mr-3 h-6 sm:h-9" alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="bg-white md:bg-transparent ">
          <Navbar.Link
            href="/"
            className={`${navActive == "/" ? "navActive underline decoration-blue200 decoration-2 underline-offset-4" : "text-dark50"
              } ml-2 nav md:ml-2 lg:ml-4 xl:ml-7 text-base hover:text-none relative`}
            active={false}
          >
            Home
          </Navbar.Link>
          <Navbar.Link
            className={`${navActive == "/#our-services" ? "navActive underline decoration-blue200 decoration-2 underline-offset-4" : "text-dark50"
              } ml-2 nav md:ml-2 lg:ml-4 xl:ml-7 text-base trigger-dropdown relative`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="flex md:-mt-1 sm:mt-0">
              <span className="pr-2 md:py-1 sm:py-0 xs:py-0">Services</span>
              <img
                src={hover ? ArrowDown : ArrowBlack}
                className="arrow-down"
              />
            </div>
            <div className="dropdown ml-8 z-10">
              <ul>
                <li>
                  <Navbar.Link
                    href="/app-development"
                    className={`${navActive == "/app-development"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-8 nav xs:ml-2 sm:ml-2 md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-4 px-2 ml-6 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Mobile_icon} style={{fill: 'green'}} className=' w-6 h-6' />
                    <p className=""> Mobile app Development</p>

                  </Navbar.Link>
                </li>
                <li>
                  <Navbar.Link
                    href="/web-development"
                    className={`${navActive == "/web-development"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-2 nav md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-6 px-2 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Web_icon1} style={{backgroundColor: "" }} className='w-6 h-5' />
                    <p className=""> Web Development</p>
                  </Navbar.Link>
                </li>
                <li>
                  <Navbar.Link
                    href="/product-design"
                    className={`${navActive == "/product-design"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-2 nav md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-4 px-2 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Product_icon} style={{}} className=' w-6 h-6' />
                    <p className="">Product Design</p>
                  </Navbar.Link>
                </li>
                <li>
                  <Navbar.Link
                    href="/quality-assurance"
                    className={`${navActive == "/quality-assurance"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-2 nav md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-6 px-2 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Quality_icon} style={{}} className=' w-6 h-6' />
                    <p className="">Quality Assurance
                    </p>

                  </Navbar.Link>
                </li>
                <li>
                  <Navbar.Link
                    href="/IT-consultion"
                    className={`${navActive == "/IT-consultion"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-2 nav md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-6 px-2 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Consu_icon} style={{}} className='w-6 h-6' />
                    <p className="">IT consulting
                    </p>
                  </Navbar.Link>
                </li>
                <li>
                  <Navbar.Link
                    href="/platform-selection"
                    className={`${navActive == "/platform-selection"
                        ? "navActive underline decoration-blue200 decoration-2 underline-offset-4"
                        : "text-dark50"
                      } ml-2 nav md:ml-2 lg:ml-4 xl:ml-5 text-base hover:text-none relative my-4 px-2 flex-row flex items-center gap-4`}
                    active={false}
                  >
                    <img src={Platform_icon} style={{}} className='w-6 h-6' />
                    <p className="">Platform selection
                    </p>

                  </Navbar.Link>
                </li>
              </ul>
            </div>
          </Navbar.Link>
          <Navbar.Link
            href="/about-us"
            className={`${navActive == "/about-us" ? "navActive underline decoration-blue200 decoration-2 underline-offset-4" : "text-dark50"
              } ml-2 nav md:ml-2 lg:ml-4 xl:ml-7 text-base hover:text-none relative`}
            active={false}
          >
            About Us
          </Navbar.Link>
          <Navbar.Link
            href="/contact-us"
            className={`${navActive == "/contact-us" ? "navActive underline decoration-blue200 decoration-2 underline-offset-4" : "text-dark50"
              } ml-2 nav md:ml-2 lg:ml-4 xl:ml-7 text-base hover:text-none relative`}
          >
            Contact Us
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
