import { Button } from "flowbite-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Banner1,
  Banner2,
  BlackBar,
  BlueArrow,
  LeftGrid,
  RightGrid,
  ServiceDetail,
  AndroidIcon,
  IosIcon,
  HybridIcon,
  AppSupportIcon,
  Deployment,
  Requirement,
  Engineering,
  QualityIcon,
} from "../../assets";
import { DevCycleCard, Header, IndustryCard } from "../../components";
const Industries = [
  {
    id: "0001",
    image: AndroidIcon,
    name: "Android app development",
  },
  {
    id: "0002",
    image: IosIcon,
    name: "Ios App Development",
  },
  {
    id: "0003",
    image: HybridIcon,
    name: "Hybrid APP Development",
  },
  {
    id: "0004",
    image: AppSupportIcon,
    name: "App Realease & Support",
  },
];

const DevCyleData = [
  {
    id: "001",
    number: "1",
    image: Requirement,
    title: "Requirement Identification",
    description:
      "We arrange and facilitate meetings to gather project requirements, thoroughly examining all details and identifying any missing elements.",
  },
  {
    id: "002",
    number: "2",
    image: Engineering,
    title: "Engineering",
    description:
      "Our team of engineers creates the interface and prototype and ultimately fulfills the client's requirements.",
  },
  {
    id: "003",
    number: "3",
    image: QualityIcon,
    title: "Quality Assurance",
    description:
      "We conduct comprehensive application testing to ensure it meets the client's requirements. Our engineers then integrate the application and, once the client is satisfied, perform the debugging process.",
  },
  {
    id: "004",
    number: "4",
    image: Deployment,
    title: "Deployment",
    description:
      "Following the final quality check, our team of experienced developers uploads the high-quality work onto servers and ensures it aligns with the project plan.",
  },
];

const AppDev = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const navigation = useNavigate()
  return (
    <>
      <Header />
      <div className="banner bg-gray200">
        <div className="mainContainer pt-40 pb-10">
          <div className="grid grid-cols-12 gap-8 pr-4 items-center">
            <div className="col-span-12 lg:col-span-6 pr-0 lg:pr-4">
              <h2 className="text-2xl md:text-4xl xl:text-5xl font-bold leading-10 w-12/12 xl:w-10/12">
                Build a disruptive mobile app
              </h2>
              <p className="text-gray50 text-lg md:text-lg sm:text-base xs:text-base pr-0 md:pr-10 lg:pr-4 mr-0 md:mr-16 lg:mr-5 py-4 w-12/12 xl:w-10/12">
                Creative design and development of mobile app for every business
                can generate extremely good results, brand loyalty and
                dependable user retention. We work closely with our clients to
                create user friendly and smartly functional apps
              </p>
              <Link to="/contact-us">
              <Button
                outline={false}
                pill={true}
                style={{
                  border: "1px solid #174781",
                  backgroundColor: "#fff",
                  padding: "0px",
                }}
                className="bg-white shadow-none outline-line"
              >
                <div className="flex justify-between px-0">
                  <span className="text-blue50 text-md pr-5">Let’s begin</span>
                  <img src={BlueArrow} />
                </div>
              </Button>
              </Link>
            </div>
            <div className="col-span-12 lg:col-span-6 py-2 flex justify-end">
              <img src={Banner2} className="w-12/12 md:w-10/12" />
            </div>
          </div>
        </div>
        <div className="bg-blue200 h-96 my-36 blueBG">
          <div className="mainContainer">
            <div className="grid grid-cols-12 gap-y-8 gap-x-34 items-center justify-between">
              <div className="col-span-12 lg:col-span-5 w-10/12">
                <h3 className="text-xl lg:text-3xl pt-5 font-bold px-5">
                  Intuitive and easy-to-use we build apps that build your
                  business
                </h3>
                <p className="text-white text-base sm:text-sm xs:text-sm md:text-base bg-blue200 h-0 lg:h-52 mt-10 pb-5 px-5">
                Our company offers end-to-end mobile app development services, 
                including design, integration, and management. We specialize in 
                creating both consumer and enterprise-level solutions, starting 
                from the ideation phase all the way to delivery and continuous support.
                </p>
              </div>
              <div className="col-span-12 lg:col-span-7 translate-y-20 lg:translate-y-16 flex justify-end">
                <img src={ServiceDetail} className="hidden md:block " width="90%" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 md:mt-60 xs:-mt-16 sm:-mt-16 bg-white">
          <div className="col-span-0 lg:col-span-1">
            <img src={LeftGrid} className="h-full" />
          </div>
          <div className="col-span-12 px-8 md:px-0 py-14 lg:col-span-10 my-10 lg:my-auto w-12/12 container mx-auto">
            <img src={BlackBar} />
            <h3 className="text-3xl font-bold py-2">
              Our mobile app development services
            </h3>
            <p className="text-gray50">
            Here are some of the key services that we specialize in, 
            leveraging our expertise and creativity:
            </p>
            <div className="grid grid-cols-12 gap-10 py-8">
              {Industries.map((item) => (
                <div
                  key={item.id}
                  className="col-span-9 px-auto md:col-span-6 lg:col-span-3"
                >
                  <IndustryCard item={item} bgColor="bg-gray400" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-0 lg:col-span-1 flex justify-end">
            <img src={RightGrid} className="h-full" />
          </div>
        </div>
        <div className="bg-gray200 py-16">
          <div className="mainContainer">
            <img src={BlackBar} />
            <h3 className="text-3xl font-bold py-2 ">Our Development Cycle</h3>
            <div className="grid grid-cols-12 gap-10 py-8 cycle mt-10 mx-auto">
              {DevCyleData.map((item) => (
                <div
                  className="col-span-9 sm:col-span-12 px-auto mx-auto lg:col-span-6"
                  key={item.id}
                >
                  <DevCycleCard item={item} smallScreen={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDev;
