import React from "react";
import { Modal, Button } from "flowbite-react";


export const Popup =({isModal, setIsModal, msg})=>{

    return(
        <Modal
    show={isModal}
    size="2xl"
    popup={true}
    onClose={()=>setIsModal(false)}
    style={{}}
  >
    {/* <Modal.Header /> */}
    <Modal.Body>
      <div className="text-center h-36 items-center justify-center flex flex-col">
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          {msg}
        </h3>
        <div className="flex justify-center gap-4 -mb-7">
          <Button
            className="bg-blue100 text-white text-xs md:text-sm uppercase rounded-full px-0 md:px-5"
            onClick={()=>setIsModal(false)}
          >
            ok
          </Button>
          {/* <Button
            color="gray"
            // onClick={onClick}
          >
            No, cancel
          </Button> */}
        </div>
      </div>
    </Modal.Body>
  </Modal>
    )
}